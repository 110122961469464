body{
  background: rgb(64,28,28);
  background: linear-gradient(90deg, rgba(64,28,28,1) 0%, rgba(193,32,32,1) 49%, rgba(193,32,32,1) 49%, rgba(64,28,28,1) 100%);
  color: #fff;
}

.cards__container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.heading{
  text-align: center;
}